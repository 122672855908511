<template>
  <v-dialog width="400" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-list-item class="lessMargin" v-on="on" v-if="!hidden">
        <v-list-item-icon style="margin-top: 10px">
          <v-icon small>mdi-credit-card-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <span>{{ $tc("payment_url") }} </span>
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-card id="consentFormLink">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
      >
        Enlace del pago:
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false">
          <v-icon id="iconClose">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-text-field outlined dense id="URLExpense" readonly v-model="URL">
          <template slot="prepend-inner">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-2" v-on="on" @click="copy()">
                  mdi-content-copy
                </v-icon>
              </template>
              {{ $t("copy") }}
            </v-tooltip>
          </template>
        </v-text-field>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "PaymentLink",
  props: ["expense", "hidden"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    URL() {
      let url = `${window.location.origin}/?#/inscription/expenses/${this.expense.id}`;
      return url;
    },
  },
  methods: {
    copy() {
      /* Get the text field */
      var copyText = document.getElementById("URLExpense");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.dialog = false;
      this.$alert(this.$t("link_copy_success"), "", "");
    },
  },
};
</script>